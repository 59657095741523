import React from 'react'
import Role from './Role'

export default function CategorieRoles({name,roles,modifyRole}) {
  return (
    <div className='contenair contenair--centre'>
        <h1>{name}</h1>
        {roles.map((role)=>(<Role modifyrole={modifyRole} key={role.id} role={role}/>))}
    </div>
  )
}
