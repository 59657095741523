import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Icone({name,path,icone}) {
    const nav = useNavigate()
  return (
    <div className='icone btn' onClick={()=>{nav(path,{replace:true})}}>
        <i className='material-symbols-outlined '>{icone}</i>
        <span className='icone__name'>{name}</span>
    </div>
  )
}
