import React from 'react'
import useFetchImgingredient from '../../hook/useFetchImgIngredient';
import Loading from '../Loading';
import scaleIngredient from '../../function/scaleIngredient';

export default function IngredientRond({ingredient,scale={active:false,numberOfPersonne:0}}) {
    const img = useFetchImgingredient(ingredient.path)
    return (
        <div className={ 'ingredient ingredient--rond contenair contenair--centre contenair--space-evently'} >
            {img === false? <Loading/> :<img src= {URL.createObjectURL(img)} alt="" />}
            <div className='ingredient__nom' >{ingredient.name}</div>
            {ingredient.quantity !== undefined ? <div className='ingredient__unit'>{scale.active ? scaleIngredient(ingredient.quantity,scale.numberOfPersonne)+' ' + ingredient.unit:ingredient.quantity +' ' + ingredient.unit}</div> :null}
        </div>
    );
}
