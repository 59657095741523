import React from 'react';
import { createContext } from 'react';
import { useState } from 'react';
export const mesageContext = createContext()
const MesageContext = ({children}) => {
    const [Mesage, setMesage] = useState({
        mesage: false,
        type: undefined
    })


    return (
        <mesageContext.Provider value={{Mesage, setMesage}}>
            {children}
        </mesageContext.Provider>
    );
};

export default MesageContext;