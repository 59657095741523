import { useCallback, useContext, useMemo, useState } from 'react';
import useFetchImgRecette from '../../hook/useFetchImgRecette';
import Loading from '../Loading';
import Tag from '../Tag';
import Modal from '../Modal';
import RecetteInfo from './RecetteInfo';
import Actions from '../Actions';
import useFetchAuth from '../../hook/useFetchAuth';
import { authcontext } from '../../contexte/auth/Authcontext';

const CardRecetteRepas = ({repas,actionActiv = {add:()=>{}}}) => {
    
    const img = useFetchImgRecette(repas.id)
    const [modal, setModal] = useState(false)
    const {fav,api,setFav,authFetch} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    async function  share(data) {
        
        try {
            await navigator.share(data)
        } catch (error) {
            console.error(error)
        }
    }

    const favToggle  = useCallback((id)=>{
        authFetch.patchJson('/recette/favori',{idRecette:id}).then((recette)=>{
            setFav((prev)=>{
                if(prev.has(id)){
                    prev.delete(id)
                }else{
                    prev.set(id,recette)
                }
                return new Map(prev)
            })
        })
    },[])
    
    const  options = useMemo(()=>(
        [
            {
                action: ()=>{share(
                    {
                        url:`${window.location.hostname}/cuisiner/${repas.id}`,
                        title:repas.name
                    }
                    )},
                html: 
                <div> 
                    <span className='align--centre'> Partager</span>
                    <span className="material-symbols-outlined btn">share</span>
                </div>
            },
            {
            action: ()=>{actionActiv.add(repas.id)},
            html: 
            <div> 
                <span className='align--centre'> Ajouter</span>
                <span className="material-symbols-outlined btn">add_circle</span>
            </div>
            }
            ,{
                action: ()=>{favToggle(repas.id)} ,
                html: 
                <div> 
                    <span className='align--centre'> Favorie</span>
                    <span className="material-icons btn">{fav.has(repas.id) ? 'favorite' : 'favorite_border'}</span>
                </div>
                }
        ]
    ),[fav,favToggle,repas.id,repas.name]) 

    return (
        <>
            {modal ? <Modal toggleModal={()=>{setModal((prev)=>(!prev))}}><RecetteInfo recette={{...repas,img}}/></Modal> : null}
            <div onClick={()=>{setModal((prev)=>(!prev))}} className='contenair contenair--column recette--recente--home recette'>

                {img === false ? <Loading/> : <img className='recette__img' src={URL.createObjectURL(img)} alt="" />}
                <div className='contenair contenair--column recette__info recette__info--home'>
                    <div className='contenair contenair--space-between'>
                        <div className='contenair contenair--centre recette__name recette__name--card'> {repas.name}</div>
                        {/* <Actions icon={'more_vert'} offset={{left:'-85px'}} options={options}/> */}
                    </div>
                    {repas.categories.length > 0 ? <div className='contenair contenair--scrollX contenair--no-warp recette__tags'>{repas.categories.map((categorie)=>(<Tag key={repas.id+categorie.id} tag={categorie}/>))}</div>:null}
                    {/* <div className='contenair contenair--scrollY recette__description'>{repas.description}</div> */}

                </div>
            </div>
        </>
    );
};

export default CardRecetteRepas;