import React, { useCallback, useContext, useState } from 'react'
import useFetchRecettes from '../hook/useFetchRecettes'
import Loading from '../composants/Loading'
import SearchBar from '../composants/SearchBar'
import useDebounce from '../hook/useDebounce'
import Pages from '../composants/Pages'
import RecetteMenu from '../composants/recettes/RecetteMenu'
import Modal from '../composants/Modal'
import AddRepas from '../composants/AddRepas'
import useFetchAuth from '../hook/useFetchAuth'
import { authcontext } from '../contexte/auth/Authcontext'

export default function Recettes() {

    const fetchAuth = useFetchAuth()
    const {api,fav,setFav,authFetch} = useContext(authcontext)

    const [page,setPage] = useState(1)
    const [text,setText] = useState('')
    const [activeModal,setActivModal] = useState(false)
    const debouncedText = useDebounce(text,400) 
    const recettes = useFetchRecettes({limit:10,page:page,text:debouncedText})
    const [recette,setRecette] = useState(false)

    
    const handelText  = useCallback((e)=>{
        const value = e.target.value
        setText(value)
         setPage(1)
    },[setText])
    
    /* const search = useCallback(()=>{
        if(text !==''){
            setMode('text')
        }else{
            setMode('all')
        }
    },[setMode,text]) */
    
    const add = useCallback((id)=>{
        const index = recettes.recettes.findIndex((recette)=>(recette.id === id))
        const recetteAdd = recettes.recettes[index]
        console.log(recetteAdd)
        setRecette(recetteAdd)
       setActivModal(true) 
    },[setActivModal,recettes,setRecette])

    const favToggle  = useCallback((id)=>{
        authFetch.patchJson('/recette/favori',{idRecette:id}).then((recette)=>{
            setFav((prev)=>{
                if(prev.has(id)){
                    prev.delete(id)
                }else{
                    prev.set(id,recette)
                }
                return new Map(prev)
            })
        })
    },[]) 

    /* if(recettes === false){
        return(
            <div className='contenair contenair--centre'>
                <SearchBar text={text} setText={handelText}/>
                <Loading/>
            </div>
        )
    }
    if(recettes === 404){
        return(
            <div className='contenair contenair--centre'>
                <SearchBar  text={text} setText={handelText}/>
                <div className='contenair'>
                    <h1>
                        Aucune recette trouver
                    </h1>
                </div>
            </div>
        )
    } */
  return (
      <div className='contenair warpper'>
         {activeModal ? <Modal className='--centre' toggleModal={()=>{setActivModal(false)}}><AddRepas recette={recette}/></Modal> :false}
        <div className='contenair  warpper'>
            <SearchBar text={text}  setText={handelText}/>
            {
                recettes === false ? 
                <Loading/> :
                 recettes === 404 ?
                 <div className='contenair'>
                    <h1>
                         Aucune recette trouver
                     </h1>
                </div>:
                <div className='contenair contenair--column contenair--column--centre  warpper'>
                    <div className='contenair contenair--scroll contenair--centre content__recettes'>
                        {recettes.recettes.map((recette)=>(<RecetteMenu actionActiv={{add:add}} key={recette.id} recette={recette}/>))}
                    </div>
                    <Pages page={page} setPage={setPage} numberOfPage={recettes.numberOfPage}/>
                </div>
            }
        </div>
    </div>
  )
}
