import React, { useCallback, useContext, useEffect, useState } from 'react';
import Ingredient from './Ingredient';
import { authcontext } from '../contexte/auth/Authcontext';
import Loading from './Loading';
import useFetchAuth from '../hook/useFetchAuth';

const Liste_de_course_home = ({week,year}) => {
    const [weekCurent] = useState({week,year})
    const [filtreExpand,setFiltreExpand] = useState(false)
    const [weekSelect,setWeekSelect] = useState(0)
    const [listedecourses,setListedecourses] = useState(false)
    const [listeDeoursesFiltre,setListeDeCourseFiltre] = useState(false)
    const [mode,setMode] = useState(0)
    const {api,authFetch} = useContext(authcontext)

    const fetchAuth = useFetchAuth()

    const selectWeek = (n)=>{
            setWeekSelect(n)
            return
    }

    const updateListeDeCourse = useCallback((newItems)=>{
        let copyListeDeCourse = {...listedecourses}
        for(const newItem of newItems){
            const index = copyListeDeCourse.items.findIndex((item)=>(item.id === newItem.id))
            console.log(index)
            if(index>= 0){
                copyListeDeCourse.items[index].state = newItem.state
            }
        }
        setListedecourses(copyListeDeCourse)
        console.log(listedecourses)
    },[listedecourses,setListedecourses])
    
    function toggleFiltre(id){
        const copyListe = {...listedecourses}
        const curent = copyListe.recettes.get(id)
        copyListe.recettes.set(id,{...curent,select:!curent.select})
        setListedecourses(copyListe)
    }

    useEffect(()=>{
        let week,year
        if(weekCurent.week + weekSelect > 52){
             week = weekCurent.week + weekSelect - 52
             year = weekCurent.year + 1
        }else{
             week = weekCurent.week + weekSelect
             year = weekCurent.year
        }
        authFetch.get(`/listedecourse/${week}-${year}`)
        .then((listeDeCourse)=>{
            listeDeCourse.recettes = new Map(listeDeCourse.recettes)
            console.log(listeDeCourse.recettes)
            setListedecourses(listeDeCourse)
        }).catch((err)=>{
            switch (err.message) {
                case 404:
                    setListedecourses(404)
                    break;
            
                default:
                    console.log(err)
                    break;
            }
        })
    },[api,weekSelect,weekCurent])

    useEffect(()=>{
        if(listedecourses !==false){
            setListeDeCourseFiltre(false)
            if(mode){
                setListeDeCourseFiltre(listedecourses)
            }else{
                const filtre = listedecourses.items.filter((item)=>(listedecourses.recettes.get(item.idRecette).select)).reduce((prev,curent)=>{
                    if(!curent.state){
                        const index = prev.nOk.findIndex((items)=>(items.idIngredient === curent.idIngredient))
                        if(index < 0){
                            prev.nOk.push({...curent,id:[curent.id]})
                            return prev
                        }
                        prev.nOk[index].id.push(curent.id)
                        prev.nOk[index].quantity += curent.quantity
                        if(prev.nOk[index].quantity>999){
                            prev.nOk[index].quantity/=1000
                            switch (prev.nOk[index].unit) {
                                case 'g':
                                    prev.nOk[index].unit = 'kg'
                                    break;
                                    case 'ml':
                                        prev.nOk[index].unit = 'L'
                                    break;
                            
                                default:
                                    break;
                            }
                        } 
                        return prev
                    }else{
                        const index = prev.ok.findIndex((items)=>(items.idIngredient === curent.idIngredient))
                        if(index < 0){
                            prev.ok.push({...curent,id:[curent.id]})
                            return prev
                        }
                        prev.ok[index].id.push(curent.id)
                        prev.ok[index].quantity += curent.quantity
                        if(prev.ok[index].quantity>999){
                            prev.ok[index].quantity/=1000
                            switch (prev.ok[index].unit) {
                                case 'g':
                                    prev.ok[index].unit = 'kg'
                                    break;
                                    case 'ml':
                                        prev.ok[index].unit = 'L'
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                        return prev
                    }
                },{ok:[],nOk:[]})
                setListeDeCourseFiltre(filtre)
            }
        }
    },[setListeDeCourseFiltre,listedecourses,mode])



    return (
        <div className='contenair contenair--column liste-de-course--home'>
            <div className='contenair contenair--centre semaine'>
                <div className={ weekSelect === 0 ? 'semaine__numero contenair contenair--centre semaine__numero--active':'semaine__numero contenair contenair--centre '} onClick={(e)=>{selectWeek(0)}}>{weekCurent.week}</div>
                <div className={ weekSelect === 1 ? 'semaine__numero contenair contenair--centre semaine__numero--active':'semaine__numero contenair contenair--centre '} onClick={(e)=>{selectWeek(1)}}>{weekCurent.week+1>52 ? (weekCurent.week+1)-52 : weekCurent.week+1}</div>
                <div className={ weekSelect === 2 ? 'semaine__numero contenair contenair--centre semaine__numero--active':'semaine__numero contenair contenair--centre '} onClick={(e)=>{selectWeek(2)}}>{weekCurent.week+1>52 ? (weekCurent.week+2)-52 : weekCurent.week+2}</div>
                <div className={ weekSelect === 3 ? 'semaine__numero contenair contenair--centre semaine__numero--active':'semaine__numero contenair contenair--centre '} onClick={(e)=>{selectWeek(3)}}>{weekCurent.week+1>52 ? (weekCurent.week+3)-52 : weekCurent.week+3}</div>
            </div>
            <div className='filtre contenair'>
                <div className='contenair contenair--centre'>
                    <i className='material-symbols-outlined  btn' onClick={()=>{setFiltreExpand((prev)=>(!prev))}}>{!filtreExpand ? 'expand_more' : 'expand_less'}</i>
                    {filtreExpand ? [...listedecourses.recettes].map((recette)=>(
                    <div className='contenair contenair--centre' key={recette[0]}>
                        <input type='checkbox' id={recette[0]} onChange={()=>{toggleFiltre(recette[0])}} checked={recette[1].select}/>
                        <label htmlFor={recette[0]}> {recette[1].name}</label>
                    </div>
                    )) :null}
                </div>
            </div>
            <div className='contenair contenair--centre liste-de-course__content'>
                {listeDeoursesFiltre === false ? <Loading/> : listeDeoursesFiltre === 404 ? <div>Aucune liste de course disponible</div> : listeDeoursesFiltre.nOk.map((ingredient,index)=>(<Ingredient key={ingredient.id+'/'+index} path={ingredient.path} name={ingredient.name} quantity={ingredient.quantity} unit={ingredient.unit} etat={ingredient.state} id={ingredient.id} update={updateListeDeCourse}/>))}
                {listeDeoursesFiltre === false ? <Loading/> : listeDeoursesFiltre === 404 ? <div>Aucune liste de course disponible</div> : listeDeoursesFiltre.ok.map((ingredient,index)=>(<Ingredient key={ingredient.id+'/'+index} path={ingredient.path} name={ingredient.name} quantity={ingredient.quantity} unit={ingredient.unit} etat={ingredient.state} id={ingredient.id} update={updateListeDeCourse}/>))}
            </div>      
        </div>
    );
};

export default Liste_de_course_home;