function scaleIngredient(quantity,numbersOfPerson){
    if(quantity === null){
        return ''
    }
    if(quantity !== null && quantity > 0){
        return quantity/2*numbersOfPerson
    }
    return null
}

export default scaleIngredient