import { useContext, useEffect, useState } from "react"
import { authcontext } from "../contexte/auth/Authcontext"
import useFetchAuth from "./useFetchAuth"

const useFetchImgingredient = (path)=>{
    const {api,authFetch} = useContext(authcontext)
    const [img,setImg] = useState(false)
    
    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        setImg(false)
        authFetch.get(path)
        .then((img)=>{
            setImg(img)
        }).catch((err)=>{
            console.log(err)
        })
    },[api,path])
    return img
}

export default useFetchImgingredient