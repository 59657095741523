import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Liste_de_course_home from '../../composants/Liste_de_course_home';

const Listecourse = () => {
    const [parm,setParam] = useSearchParams()
    const week = parseInt(parm.get('week')) 
    const year = parseInt(parm.get('year')) 
    
    return (
           <Liste_de_course_home week={week} year={year}/>
    );
};

export default Listecourse;