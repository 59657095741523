import React, { useContext, useRef, useState } from 'react'
import CustumSelect from './CustumSelect'
import useFetchAuth from '../hook/useFetchAuth'
import { authcontext } from '../contexte/auth/Authcontext'

export default function CustomSelectCategorie({categorie,select,add,close}){
    const fetchAuth = useFetchAuth()
    const {api,authFetch} = useContext(authcontext)

    const [addCategorie,setAddCategorie] = useState(false)
    const nameCategorieRef = useRef()

    function postCategorie(e){
        e.preventDefault()
       
        authFetch.postJson(`/categorie`,{name:nameCategorieRef.current.value}).then((newCategorie)=>{
            setAddCategorie(false)
            add(newCategorie)
        }).catch((err)=>{
            console.error(err)
        })
    }

    function closeSelect(){
        setAddCategorie(false)
        close()
    }

  return (
    <div className='contenair contenair--column custumSelect contenair--centre'>
        <CustumSelect close={closeSelect} select={select} options={categorie.map((categorie)=>({data:categorie,html:<span>{categorie.name}</span>}))}/>
        {addCategorie ? 
        <div>
            <label htmlFor="categorieName">Nom de la categorie: </label>
            <input ref={nameCategorieRef} type="text" id='categorieName'/>
            <button onClick={postCategorie} className='bnt btn--blue'>Cree la categorie</button>
        </div> 
        : <button onClick={()=>{setAddCategorie(true)}} className='btn material-symbols-outlined'>add</button>}
    </div> 
  )
}
