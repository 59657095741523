import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { render } from "react-dom";

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
        <App />
    </BrowserRouter>
); */

render(
  <BrowserRouter>
        <App />
  </BrowserRouter>,
  document.getElementById('root')  
)

