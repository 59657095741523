import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import Loading from '../../composants/Loading';
import useFetchAuth from '../../hook/useFetchAuth';
import FetchAuthObject from '../../class/FetchAuthObject';


export const authcontext = createContext()
const AuthContext = ({children}) => {
    const [user, setUser] = useState(false)
    const [accessToken,setAccessToken] = useState('')
    const [login, setLogin] = useState(false)
    const [api,setApi] = useState(process.env.REACT_APP_API_ADRESSE)
    const [fav,setFav] = useState(false)
    const [authFetch,setAuthFecht] = useState(null)

    useEffect(()=>{
        fetch('https://auht-api.edo.ovh/token',{
            credentials: 'include'
        }).then((res)=>{
            if(res.status === 200){
                return res.json()
            }
            else{
                throw new Error(res.status)
            }
        }).then((data)=>{
            if(data){
                console.log(data)
                setUser(data.user)
                setAccessToken(data.accessToken)
                setAuthFecht(new FetchAuthObject(data.accessToken,'https://MenuMatic-api.edo.ovh','https://auht-api.edo.ovh/token'))
            }
            setLogin(true)
        }).catch((err)=>{
            console.log(err)
            setUser(false)
            setLogin(true)      
        })
    },[])
    return (
       <authcontext.Provider value={{user, setUser,setAccessToken,accessToken,api,fav,setFav,authFetch,setAuthFecht}}>
            {login ?children:<Loading/>}
       </authcontext.Provider>
    );
};

export default AuthContext;