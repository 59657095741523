import React from 'react'

export default function Modal({children,toggleModal,className = ''}) {
  return (
    <div onClick={toggleModal} className={'modal contenair contenair--centre ' + 'modal' + className}>
      <div className={`modal__body modal__body${className}`}  onClick={(e)=>{e.stopPropagation()}}>
        {children}
      </div>
    </div>
  )
}
