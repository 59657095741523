
class Repas{

    constructor({recette,numberOfPresonne,idMenu,id}){
        this.id = id
        this.recette = recette
        this.numberOfPresonne = numberOfPresonne
        this.idMenu = idMenu
    }
    setIdMenu(idMenu){
        this.idMenu = idMenu
    }
}

export default Repas