import React, { useCallback, useEffect, useState } from 'react'
import { useContext } from 'react'
import { authcontext } from '../../contexte/auth/Authcontext'
import put from '../../function/put'
import { useNavigate } from 'react-router-dom'
import useFetchAuth from '../../hook/useFetchAuth'

export default function Preference() {
    const {api,setUser,authFetch} = useContext(authcontext)
    const [numberOfPersonne,setNumberOfPersonne] = useState(1)
    const [numberOfRepas,setNumberOfRepas] = useState(1)
    const nav = useNavigate()
    const [maxPersonne,setMaxPersonne] = useState([])
    const [maxRepas,setMaxRepas] = useState([])

    const fetchAuth = useFetchAuth()
    
    


    const handelSelect = (e,set)=>{
        const {target} = e
        set(target.value)
    }

    useEffect(()=>{
        const personne = 10
        const repas = 20
        let tab1 = []
        let tab2 = []
        let i = 0
        while (i<personne) {
            tab1.push(i)
            i++
        }
        i = 0
        while (i<repas) {
            tab2.push(i)
            i++
        }
        setMaxPersonne(tab1)
        setMaxRepas(tab2)

    },[])
    
    const valider = ()=>{
        const data = new FormData() 
        data.append('numberOfPersonne',numberOfPersonne)
        data.append('numberOfRepas',numberOfRepas)
        authFetch.put(`/user/preference`,data).then((preference)=>{
            setUser((prevUser)=>({...prevUser,preference}))
            nav('/home')
        }).catch((err)=>{
            console.log(err)
        })
        }
         
    

  return (
    <div className='preference contenair contenair--centre'>
        {<div className='group-form contenair contenair--centre'>
            <div className='group-form__titel'>Preference</div>
            
            <div className='group-form__group contenair contenair--centre'>
                <label htmlFor="numberOfRepas">nomber de repas par semaine</label>
                <select onChange={(e)=>{handelSelect(e,setNumberOfRepas)}} name="numberOfRepas" id="numberOfRepas-select" value={numberOfRepas}>
                    {maxRepas.map((val,index)=>(<option key={'numberOfRepas'+val} value={val}>{val}</option>))}
                </select>
            </div>

            <div className='group-form__group contenair contenair--centre'>
                <label htmlFor="numberOfPersonne">nomber de personne par repas</label>
                <select onChange={(e)=>{handelSelect(e,setNumberOfPersonne)}} name="numberOfPersonne" id="numberOfPersonne-select" value={numberOfPersonne}>
                    {maxPersonne.map((val,index)=>(<option key={'numberOfPersonne'+val} value={val}>{val}</option>))}
                </select>
            </div>

            <div className='group-form__btn'>
                <div onClick={()=>{valider()}} className='btn btn--big btn--green'>valider</div>
            </div>
        </div>}
    </div>
  )
}
