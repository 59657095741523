import React, { useEffect, useState } from 'react';
import getsemaine from '../../function/getsemaine';
import SemaineNumero from '../../composants/SemaineNumero';
import Menu from '../../composants/Menu';
import { useSearchParams } from 'react-router-dom';

const MesMenus = () => { 

    const [parm,setParam] = useSearchParams()
    const week = parm.has('week') ? parseInt(parm.get('week')) : getsemaine(new Date())  
    const year = parm.has('year') ? parseInt(parm.get('year')) : new Date().getFullYear()
    
    const [semaineSelect,setSemaineSelect] = useState({week,year})
    const [numeroSemaine,setNumeroSemaine] = useState(false)
    useEffect(()=>{
        const semaineCurent = {week:getsemaine(new Date()),year:new Date().getFullYear()}
        let tab = []
                    for (let i = 0; i < 52; i++) {
                        let semaine ={}
                            if(i===0){
                                if(semaineCurent.week-i+1 < 1){
                                    semaine.week = semaineCurent.week-i+1+52
                                    semaine.year = semaineCurent.year - 1
                                }else{
                                    semaine.week = semaineCurent.week-(i+1)
                                    semaine.year = semaineCurent.year 
                                }
                            }else if(i === 1){
                                    semaine = semaineCurent
                            }else{
                                if(semaineCurent.week +(i-1) > 52){
                                    semaine.week = semaineCurent.week+(i-1)-52
                                    semaine.year = semaineCurent.year + 1
                                }else{
                                    semaine.week = semaineCurent.week+(i-1)
                                    semaine.year = semaineCurent.year 
                                }
                            }
                            tab.push(<SemaineNumero key={semaine.week+'/'+semaine.year} select={semaineSelect} set={setSemaineSelect} semaine={semaine}/>)
                        }
                        setNumeroSemaine(tab)

    },[setNumeroSemaine,setSemaineSelect,semaineSelect])
    
    return (
        <div className='contenair warpper '>
            <div className='contenair contenair--scroll  semaine warpper__header '>
                {numeroSemaine}
            </div>
            <Menu semaine={semaineSelect}/>
        </div>

        

        
    );
};

export default MesMenus;