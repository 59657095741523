function getYear(date,n=0){
    const year = date.getFullYear()
    const dayone = new Date(year,0)
    const dateint = Math.floor((date - dayone)/(24*60*60*1000))
    const result = Math.ceil(dateint/7)
    if(result + n > 52){
       return year + 1 
    }
    return year  
}

module.exports = getYear 