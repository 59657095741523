import { useContext, useEffect, useState } from "react"
import { authcontext } from "../contexte/auth/Authcontext"
import useFetchAuth from "./useFetchAuth"

const useFetchImgRecette = (idRecette)=>{
    const {api,authFetch} = useContext(authcontext)
    const [img,setImg] = useState(false)

    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        authFetch.get(`/img/recette/recette${idRecette}/description/description.jpeg`)
        .then((img)=>{
            setImg(img)
        }).catch((err)=>{
            console.log(err)
        })
    },[api,idRecette])
    return img
}

export default useFetchImgRecette