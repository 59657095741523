import React, { useState } from 'react'
import Loading from '../Loading'
import Tag from '../Tag'
import IngredientRond from '../ingredient/IngredientRond'
import useFetchImgRecette from '../../hook/useFetchImgRecette'
export default function RecetteMenuAdd({recette,add}) {
    const img = useFetchImgRecette(recette.id)
    const [extend,setExtend] = useState(false)
    return (
        <div className={extend ?'contenair contenair--column recette recette--menu recette--menu--extend' : 'contenair contenair--column recette recette--menu'} >
                
                <div>
                    <img onClick={()=>{add(recette)}} src="add.png" alt="add" />
                </div>
                <div className='contenair contenair--column  ' onClick={()=>{setExtend(!extend)}}>
                    {img === false ? <Loading/> :<img className='recette__img' src={URL.createObjectURL(img)} alt="" />}
                    <div className='contenair contenair--column recette__info'>
                        <div className='contenair recette__name'> {recette.name}</div>
                        {recette.categories.length > 0 ? <div className='contenair contenair--scrollX contenair--no-warp recette__tags'>{recette.categories.map((categorie)=>(<Tag key={recette.id+categorie.id} tag={categorie}/>))}</div>:null}
                        <div className='contenair contenair--scrollY recette__description'>{recette.description}</div>
                        {extend ? 
                        <div className='contenair  contenair--scrollY menu__ingredients recette__ingredients'>
                            {recette.ingredients.map((ingredient)=>(<IngredientRond key={recette.id+'/'+ingredient.idIngredient} ingredient={ingredient}/>))}
                        </div>:null}
                    </div>
                </div>
            </div>
      )
}
