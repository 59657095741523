import React from 'react'
import IngredientRond from '../ingredient/IngredientRond'
import Tag from '../Tag'
import { useNavigate } from 'react-router-dom'

export default function RecetteInfo({recette}) {
    const nav = useNavigate()
  return (
    
        <div className='grid grid__4c  grid__8r constenaire--centre recette recette--info'>
            <div className='recette__img recette__img--info contenair contenair--centre'>
                <img alt='img ilustration recette' src={URL.createObjectURL(recette.img)}/>
            </div>
            <div className='contenair contenair--column recette__info recette__info--info'>
                        <div className='recette__name'>{recette.name}</div>
                        <div className='contenair contenair--centre contenair--no-wrap '>
                            {recette.categories.map((categorie)=>(<Tag key={categorie.id} tag={categorie} color={categorie.color}/>))}
                        </div>
                        <div className='recette__duration'>temps : {recette.duration}</div>
                        <div className='contenair contenair--scrollY recette__description'>{recette.description}</div>
            </div>

            <div className='contenair contenair--centre contenair--scrollY recette__ingredients--info'>   
                {recette.ingredients.map((ingredient)=>(<IngredientRond scale={{active:true,numberOfPersonne:recette.numberOfPresonne}} key={ingredient.idIngredient} ingredient={ingredient}/>))}
            </div>
            <button className='btn btn--big btn--green recette__btn recette__btn--info' onClick={()=>{nav('/cuisiner/' + recette.id+`?nbPersonne=${recette.numberOfPresonne}`,{replace:true})}}>Cusiner</button>
        </div>
    
  )
}
