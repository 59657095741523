import React from 'react'

export default function SearchBar({text,setText}) {
  return (
    <div className='contenair contenair--centre'>
        <div className='group-form__search contenair contenair--centre'>
          <input autoFocus value={text} onChange={(e)=>{setText(e)}} type="text" />
          <img src="search.png" alt="search" />
        </div>
    </div>
  )
}
