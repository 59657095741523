import React, { useState } from 'react'

export default function SideBar({children}) {

    const [active,setActive] = useState(false)

    function toggle(e){
        e.stopPropagation()
        setActive((prev)=>{
            return !prev
        })
    }

    if(active) return (
        <div className=' sideBar__warpper' onClick={toggle}>
            <div className={`contenair sideBar sideBar--active`} /* onClick={(e)=>{e.stopPropagation()}} */>
                <div className='contenair contenair--left'>
                    <i className='btn material-symbols-outlined' onClick={toggle}>menu</i>
                </div>
                {children}
            </div>
        </div>
    )

    return (
        <div className={`sideBar`}>
            <i className='btn material-symbols-outlined' onClick={toggle}>menu</i>
        </div>
    )
}
