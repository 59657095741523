import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useFetchImgRecette from '../../hook/useFetchImgRecette'
import useFetchRecettte from '../../hook/useFetchRecette'
import { useParams, useSearchParams } from 'react-router-dom'
import Loading from '../../composants/Loading'
import Tag from '../../composants/Tag'
import IngredientRond from '../../composants/ingredient/IngredientRond'
import Etape from '../../composants/Etape'
import { authcontext } from '../../contexte/auth/Authcontext'
import Actions from '../../composants/Actions'
import Modal from '../../composants/Modal'
import AddRepas from '../../composants/AddRepas'
import useFetchAuth from '../../hook/useFetchAuth'


export default function Cuisiner() {
    const {idRecette} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [nbPers,setNbPers] = useState(2)
    const recette = useFetchRecettte(idRecette) 
    const img = useFetchImgRecette(idRecette)
    const {user,fav,api,setFav,authFetch} = useContext(authcontext)
    console.log(fav.has(recette.id))
    const [modal,setModal] = useState(false)
    const fetchAuth = useFetchAuth()
    

    async function  share(data) {
        
        try {
            await navigator.share(data)
        } catch (error) {
            console.error(error)
        }
    }

    function toggleModal(){
        setModal((prev)=>(!prev))
    }

    const favToggle  = useCallback((id)=>{
        authFetch.patchJson('/recette/favori',{idRecette:id}).then((recette)=>{
            setFav((prev)=>{
                if(prev.has(id)){
                    prev.delete(id)
                }else{
                    prev.set(id,recette)
                }
                return new Map(prev)
            })
        })
    },[])
    
    const  options = useMemo(()=>(
        [
            {
                action: ()=>{share(
                    {
                        url:window.location.href.split('?')[0],
                        title:recette.name
                    }
                    )},
                html: 
                <div> 
                    <span className='align--centre'> Partager</span>
                    <span className="material-symbols-outlined btn">share</span>
                </div>
            },
            {
            action: toggleModal,
            html: 
            <div> 
                <span className='align--centre'> Ajouter</span>
                <span className="material-symbols-outlined btn">add_circle</span>
            </div>
            }
            ,{
                action: ()=>{favToggle(recette.id)} ,
                html: 
                <div> 
                    <span className='align--centre'> Favorie</span>
                    <span className="material-icons btn">{fav.has(recette.id) ? 'favorite' : 'favorite_border'}</span>
                </div>
                }
        ]
    ),[fav,favToggle,recette.id,recette.name]) 

    useEffect(()=>{
        if(searchParams.get('nbPersonne') === null){
            let copySearchParams = Object.fromEntries(searchParams)
            console.log(copySearchParams)
            setNbPers(user.preference.nbPers)     
        }else{
            setNbPers(parseInt(searchParams.get('nbPersonne')))
        }

    },[searchParams,user])

    function modifyNbPers(num){
        setNbPers((prevNb)=>(prevNb+num))
    }

    if(recette === false){
        return <Loading/>
    }

  return (
      <div className='contenair contenair--centre'>
         {modal && <Modal toggleModal={toggleModal}><AddRepas recette={recette}/></Modal>}
        <div className='grid grid__4c  grid__8r constenaire--centre recette recette--cuisiner'>
            <div className='recette__img recette__img--cuisiner contenair contenair--centre'>
                {img === false ? <Loading/> : <img alt='img ilustration recette' src={URL.createObjectURL(img)}/>}
            </div>
            <div className='contenair  recette__info recette__info--cuisiner'>
                      <div className=' recette__info--cuisiner__warpper contenair contenair--column'>
                        <div className='contenair contenair--centre recette__name'>{recette.name}</div>
                        <div className='contenair contenair--centre contenair--no-wrap  recette__info__tags'>
                            {recette.categories.map((categorie)=>(<Tag key={categorie.id} tag={categorie} color={categorie.color}/>))}
                        </div>
                        <div className='recette__duration'>temps : {recette.duration}</div>
                        <div className='contenair contenair--centre contenair--scrollY recette__description'>{recette.description}</div>
                      </div>
                        <div className='conteanir recette__option'>
                            <Actions icon={'more_vert'} offset={{left:'-85px'}} options={options}/>
                        </div>
            </div>

                <div className='contenair contenair--scrollY recette__ingredients--cuisiner'>
                    <div className='contenair contenair--space-evently recette__ingredients_nbPersone'>
                            <span onClick={()=>{modifyNbPers(-1)}} className="material-symbols-outlined btn">do_not_disturb_on</span>
                            <span className='align--centre'>{nbPers}</span>
                            <span onClick={()=>{modifyNbPers(1)}} className="material-symbols-outlined btn">add_circle</span>
                    </div>
                    <div className='contenair'>
                        {recette.ingredients.map((ingredient)=>(<IngredientRond scale={{active:true,numberOfPersonne:nbPers}} key={ingredient.idIngredient} ingredient={ingredient}/>))}
                    </div>
                </div>
                
                    
                        <div className='recette__etapes--cuisiner contenair contenair--space-evently contenair--scroll'>
                            {recette.etapes.map((etape)=>(<Etape etape={etape} key={etape.id} idRecette={recette.id} classWarper={'recette__etape recette__etape--cuisiner'} classInstruction={'etape__instruction--cuisiner '}/>))}
                        </div>
        </div>
    </div>
  )
}
