import { useContext } from "react"

import { useLocation, useNavigate } from "react-router-dom"
import { authcontext } from "../contexte/auth/Authcontext"

const useFetchAuth = ()=>{
    const {accessToken,setAccessToken,setUser} = useContext(authcontext)
    const nav = useNavigate()
    const location = useLocation()
    
    return (url,method,data,abort)=>{
        const setHeadrer = (method,data,token,abort)=>{
            let header
            switch (method) {
                case 'get':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${token}`
                        },
                        method: 'get',
                        credentials: 'include',
                        signal:abort
                    }
                    break;
                case 'postJson':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": 'application/json',
                            'authorization': `Bearer ${token}`
                        },
                        method: 'post',
                        body: JSON.stringify(data),
                        credentials: 'include'
                    }
                    break; 
                case 'post':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            'authorization': `Bearer ${token}`
                        },
                        method: 'post',
                        body: data,
                        credentials: 'include'
                    }
                    break;
                case 'put':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            'authorization': `Bearer ${token}`
                        },
                        method: 'put',
                        body: data,
                        credentials: 'include'
                    }
                     break;

                     case 'putJson':
                        header = {
                            headers: {
                                'Accept': 'application/json',
                                'authorization': `Bearer ${token}`
                            },
                            method: 'put',
                            body: JSON.stringify(data),
                            credentials: 'include'
                        }
                         break;
                     
                case 'patchJson':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": 'application/json',
                            'authorization': `Bearer ${token}`
                        },
                        method: 'PATCH',
                        body: JSON.stringify(data),
                        credentials: 'include'
                    }
                    break;    
                default:
                
                    break;
            }
            return header
        }
        const headers = setHeadrer(method,data,accessToken,abort)
        return new Promise(async (resolve,reject)=>{

            const tryFetch = (url,header)=>{
                return new Promise(async (resolve,reject)=>{
                    const responce = await fetch(url,header)
                    if(!responce.ok) return reject(responce.status)
                    if(responce.headers.get("Content-Type")?.includes('image')){
                        const data = await responce.blob()
                        return resolve(data)
                    }
                    if(responce.headers.get("Content-Type")?.includes('application/json')){
                        const data = await responce.json()
                        return resolve(data)
                    }
                    if(responce.headers.get("Content-Type")?.includes('text/plain')){
                        const data = await responce.text()
                        return resolve(data)
                    }
                    return resolve(null)
                })
            }

            try {
                const data = await tryFetch(url,headers)
                resolve(data)
            } catch (error) {
                console.error(error)
                if(error !== 401 && error !== 403){
                    return reject(error)
                }
                try {
                   const responce  = await fetch('https://auht-api.edo.ovh/token',{credentials: 'include'})
                   if(responce.ok){
                    const newToken = await responce.json()
                    setAccessToken(newToken.accessToken)
                    setUser(newToken.user)
                    headers.headers.authorization = `Bearer ${newToken.accessToken}`
                    console.log(headers.headers)
                    const data = await tryFetch(url,headers)
                    return resolve(data) 
                   }
                   throw new Error('fail refresh token',{cause:responce.status})
                } catch (error) {
                    console.error(error)
                    if(error !== 'fail refresh token'){
                        return reject(error)
                    }
                    setUser(false)
                    setAccessToken("")
                    nav('/login',{replace:true,state:{from:location}})
                    reject(error)
                }
            }

        })

    }
}

export default useFetchAuth
