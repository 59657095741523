const postJson = (data)=>{
    return {
        headers: {
            'Accept': 'application/json',
            "Content-Type": 'application/json'
        },
        method: 'post',
        body: data,
        credentials: 'include'
    }
}

export default postJson