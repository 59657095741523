import React, { useEffect, useRef, useState } from 'react'

export default function Field({className = '',field,input,err}){
   const label = useRef()
   const [loading,setLoading] = useState(false)
    useEffect(()=>{
      if(!loading){
        setLoading(true)
      }else{
      }
    },[loading])
   

  if(err){
    return(
      <div className="group-form__field group-form__field--err contenair ">
          <span className='group-form__field__border group-form__field__border--err '>
            <span className='group-form__field__border--left'></span >
            <span style={loading ? {width:label.current.offsetWidth}:{}} className='group-form__field__border--middel'>
              <span ref={label} className='group-form__field__name'>{field.label}</span>
            </span>

            <span className='group-form__field__border--botum contenair contenair--space-envently'>
              <span className='group-form__field__border--botum__left'></span>
              <span className='group-form__field__border--botum__midel--err'>
                <span  className='group-form__field__err'>{err}</span>
              </span>
              <span className='group-form__field__border--botum__right'></span>
            </span>
            <span className='group-form__field__border--right__right'></span>
          </span>
          {className.includes('field--edit') ? 
          <input className='group-form__field__value' onChange={input.onChange} value={input.value} type={field.type} name={field.name}/>:
          <span className='group-form__field__value contenair'>{field.value}</span>
        }
      </div>
    )
  }
  return (
    <div className="group-form__field contenair ">
          <span className='group-form__field__border'>
            <span className='group-form__field__border--left'></span >
            <span style={loading ? {width:label.current.offsetWidth}:{}} className={`group-form__field__border--middel `}>
              <span ref={label} className={`group-form__field__name`}>{field.label}</span>
            </span>
            <span className='group-form__field__border--botum contenair contenair--space-envently'>
              <span className='group-form__field__border--botum__left'></span>
              <span className='group-form__field__border--botum__right'></span>
            </span>
            <span className='group-form__field__border--right'></span>
          </span>
          {className.includes('field--edit') ? 
          <input className='group-form__field__value' onChange={input.onChange} value={input.value} type={field.type} name={field.name}/>:
          <span className='group-form__field__value contenair'>{field.value}</span>
        }
    </div>
  )
}
