import React, { useContext, useEffect, useState } from 'react'
import useFetchAuth from '../../hook/useFetchAuth'
import Loading from '../../composants/Loading'
import { authcontext } from '../../contexte/auth/Authcontext'
import IngredientRond from '../../composants/ingredient/IngredientRond'
import Tag from '../../composants/Tag'
import Modal from '../../composants/Modal'
import CustomSelectCategorie from '../../composants/CustomSelectCategorie'

export default function CategoriserIgredient() {

    const {api} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    const [categories,setCategories] = useState(false)
    const [ingredient,setIngredient] = useState(false)
    const [loading,setLoading] = useState(true)
    const [modal,setModal] = useState(false)

    useEffect(()=>{
        Promise.all([
            fetchAuth(`${api}/ingredient/a-categoriser`,'get'),
            fetchAuth(`${api}/categorie/page/0/limit/0`,'get')
        ]).then((result)=>{
            setCategories(result[1])
            setIngredient({...result[0],categories:new Map()})
            setLoading(false)
        }).catch((err)=>{
            if(err === 404){
                setIngredient(404)
                setLoading(false)
            }
            console.error(err)
        })

    },[])

    function addCategorie(categorie){
        setIngredient((prev)=>{
            prev.categories.set(categorie.id,categorie)
            return {...prev}
        })
        setModal(false)
    }

    function newCategorie(categorie){
        setCategories((prev)=>([...prev,categorie]))
    }

    function valider(){
        const data = {ingredient:{id:ingredient.id,categories:Array.from(ingredient.categories,(entrie)=>(entrie[0]))}}
        console.log(data)
        fetchAuth(`${api}/ingredient/categoriser`,'patchJson',data).then(()=>{
            return fetchAuth(`${api}/ingredient/a-categoriser`,'get')
        }).then((ingredient)=>{
            setIngredient({...ingredient,categories:new Map()})
        }).catch((err)=>{
            if(err === 404){
                setIngredient(404)
                
            }
            console.error(err)
        })
    }

    if(loading) return <Loading/>

    if(ingredient === 404) return <span>Aucun ingredient à categoriser</span>

  return (
    <div className='contenair contenair--centre categorieIngredient'>
         {modal ? <Modal toggleModal={()=>{setModal(false)}}><CustomSelectCategorie close={()=>{setModal(false)}} categorie={categories.filter((categorie)=>(!ingredient.categories.has(categorie.id)))} add={newCategorie} select={addCategorie}/></Modal>:null }
        <IngredientRond ingredient={ingredient}/>
        {ingredient.categories.size === 0 ? <div className='categorieIngredient__categorie contenair contenair--centre contenair--space-evently'><span>Aucune Categorie lier à cette ingredient</span>  <button onClick={()=>{setModal(true)}} className='btn material-symbols-outlined'>add</button></div>:
         <div className='categorieIngredient__categorie contenair contenair--centre contenair--space-evently'>
            {Array.from(ingredient.categories,(entrie)=>({key:entrie[0],tag:entrie[1]})).map((categorie)=>(<Tag tag={categorie.tag} key={ `tag/${categorie.key}`}/>))}
            <button onClick={()=>{setModal(true)}} className='btn material-symbols-outlined'>add</button>
        </div> }
        <button onClick={valider} className='btn btn--blue'>Valider</button> 
    </div>
  )
}
