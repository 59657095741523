

const Tag = ({color,tag,edit={active:false}}) => {
    /* console.log(edit) */
    
    
    return (
        <div className={edit.active ? 'tag tag--edit' : "tag"} style={{background: color}}>
            <span>{tag.name}</span>
            {edit.active ?<div className='btn btn__cross' onClick={()=>{edit.drop(edit.index)}}>x</div>:<div/>} 
        </div>
    );
};

export default Tag;