

const useFetch = ()=>{
    return (url,method,data)=>{
        const setHeadrer = (method,data)=>{
            let header
            switch (method) {
                case 'get':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            
                        },
                        method: 'get',
                        credentials: 'include'
                    }
                    break;
                case 'postJson':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": 'application/json',
                            
                        },
                        method: 'post',
                        body: JSON.stringify(data),
                        credentials: 'include'
                    }
                    break; 
                case 'post':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            
                        },
                        method: 'post',
                        body: data,
                        credentials: 'include'
                    }
                    break;
                case 'patchJson':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": 'application/json',
                        
                        },
                        method: 'PATCH',
                        body: JSON.stringify(data),
                        credentials: 'include'
                    }
                    break;
                case 'delete':
                    header = {
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": 'application/json',
                        
                        },
                        method: 'DELETE',
                        credentials: 'include'
                    }
                    break    
                default:
                
                    break;
            }
            return header
        }
        const headers = setHeadrer(method,data)
        return new Promise(async (resolve,reject)=>{

            const tryFetch = (url,header)=>{
                return new Promise(async (resolve,reject)=>{
                    const responce = await fetch(url,header)
                    if(!responce.ok) return reject(responce.status)
                    if(responce.headers.get("Content-Type")?.includes('image')){
                        const data = await responce.blob()
                        return resolve(data)
                    }
                    if(responce.headers.get("Content-Type")?.includes('application/json')){
                        const data = await responce.json()
                        return resolve(data)
                    }
                    if(responce.headers.get("Content-Type")?.includes('text/plain')){
                        const data = await responce.text()
                        return resolve(data)
                    }
                    return resolve(null)
                })
            }

            try {
                const data = await tryFetch(url,headers)
                resolve(data)
            } catch (error) {
                console.error(error)
                return reject(error)
            }

        })

    }
}

export default useFetch
