import { useContext, useEffect, useState } from "react"
import { authcontext } from "../contexte/auth/Authcontext"
import useFetchAuth from "./useFetchAuth"


const useFetchRecettes = ({text,page=1,limit=0})=>{

    const {api,authFetch} = useContext(authcontext)
    const [recettes,setRecette] = useState(false)

    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        const abor = new AbortController()
        const mode  = text === '' ? 'all' : 'text'
        console.log(mode,text)
        setRecette(false)
        if(mode === 'all'){
            authFetch.get(`/recette/page/${page}/limit/${limit}?getingredient=true`,abor.signal)
            .then((recettes)=>{
                setRecette(recettes) 
            }).catch((err)=>{
                console.log(err)
                if(err === 404){
                    setRecette(404)
                }
                console.error(err)
            }) 
        }else if(mode ==='text'){
                authFetch.get(`/recette/searchByName/${text}/page/${page}/limit/${limit}?getIngredient=true`,abor.signal)
                .then((recettes)=>{
                    setRecette(recettes) 
                }).catch((err)=>{
                    console.log(err)
                    if(err  === 404){
                        console.log(404)
                        setRecette(404)
                    }
                    console.error(err)
                })

            
        }
        return ()=>{
            abor.abort()
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setRecette,api,page,limit,text])
    return recettes
}

export default useFetchRecettes