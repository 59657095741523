import React from 'react'

export default function Pages({setPage,numberOfPage,page}) {
  return (
    <div className='contenair contenair--centre pages'>
        <div className='btn btn--nav' onClick={()=>{if(page - 1 > 0){setPage(page-1)}}}>Précédent</div>
        {[...new Array(numberOfPage).fill(null).map((val,index)=>{
            const i = index + 1
            const html = <div className={i === page ? 'btn btn--page btn--page--active':'btn btn--page' } key={'page/'+i} onClick={()=>{setPage(i)}}>{i}</div>
            if(numberOfPage < 11){
                return html
            }
            if(page<8){
                if(i < 11){
                    return html
                }
                if(i === 11){
                    return <div className='btn btn--page btn--page--active' key={'page/'+i}>...</div>
                }
                if(numberOfPage - i < 2){
                    return html
                }
                return  null
            }else{
                if(i < 3){
                    return html
                }
                if(i === 3){
                    return <div className='btn btn--page btn--page--active' key={'page/'+i}>...</div>
                }
                if(i - page > -4  && i - page < 4){
                    return html
                }
                if(i-page === 4){
                    return <div className='btn btn--page btn--page--active' key={'page/'+i}>...</div>
                }
                if(numberOfPage - i < 2){
                    return html
                }
                return  null
            }
        })]}
        <div className='btn btn--nav' onClick={()=>{if(page + 1 <= numberOfPage){setPage(page+1)}}}>Suivant</div>
    </div>
  )
}
