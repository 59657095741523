import { useContext, useEffect, useState } from "react"
import { authcontext } from "../contexte/auth/Authcontext"
import useFetchAuth from "./useFetchAuth"

const useFetchImgEtape = (idRecette,indexEtape)=>{
    const [img,setImg] = useState(false)
    const {api,authFetch} = useContext(authcontext)
    
    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        authFetch.get(`/img/recette/recette${idRecette}/etape/etape${indexEtape}.jpeg`)
        .then((img)=>{
            setImg(img)
        }).catch((err)=>{
            console.error(err)
        })
    },[api,setImg,idRecette,indexEtape])
    return img
}

export default useFetchImgEtape