import React from 'react'
import BtnBascule from '../BtnBascule'

export default function Role({role,modifyrole}) {
  function change (state){
    role.state = state
    modifyrole(role)
  }
  return (
    <div className='contenair contenair--centre'>
        <span>{role.designation}</span>
        <BtnBascule initState={role.state} onClick={change}/>
    </div>
  )
}
