import React, { useContext } from 'react'
import { authcontext } from '../../contexte/auth/Authcontext'
import authorization from '../../function/authorization'
import Icone from '../../composants/Icone'

export default function HomeAdmin() {
    const icones =[
        {
            auth:[1000],
            name:'Categoriser les ingredient',
            path:'/admin/categoriser-ingredient',
            icone:'grocery'
        }
    ]
    const {user} = useContext(authcontext)

  return (
    <div className='grid'>
        {icones.filter((icone)=>(authorization(user.roles,icone.auth))).map((icone,index)=>(<Icone key={`icone/${index}`} path={icone.path} name={icone.name} icone={icone.icone}/>))}
    </div>
  )
}
