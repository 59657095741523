import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Tag from '../Tag'
import Loading from '../Loading'
import IngredientRond from '../ingredient/IngredientRond'
import useFetchImgRecette from '../../hook/useFetchImgRecette'
import { authcontext } from '../../contexte/auth/Authcontext'
import useFetchAuth from '../../hook/useFetchAuth'
import Actions from '../Actions'

export default function RecetteMenu({gerer,recette,numbersOfpersonne,modify,actionActiv={
    add:()=>{},
    favorie:false,
}}) {

    
    const img = useFetchImgRecette(recette.id)
    const [extend,setExtend] = useState(false)
    const nav = useNavigate()
    const {fav,api,setFav,authFetch} = useContext(authcontext)
    const fetchAuth = useFetchAuth()

    async function  share(data) {
        
        try {
            await navigator.share(data)
        } catch (error) {
            console.error(error)
        }
    }

    const favToggle  = useCallback((id)=>{
        authFetch.patchJson('/recette/favori',{idRecette:id}).then((recette)=>{
            setFav((prev)=>{
                if(prev.has(id)){
                    prev.delete(id)
                }else{
                    prev.set(id,recette)
                }
                return new Map(prev)
            })
        })
    },[])
    
    const  options = useMemo(()=>(
        [
            {
                action: ()=>{share(
                    {
                        url:`${window.location.hostname}/cuisiner/${recette.id}`,
                        title:recette.name
                    }
                    )},
                html: 
                <div> 
                    <span className='align--centre'> Partager</span>
                    <span className="material-symbols-outlined btn">share</span>
                </div>
            },
            {
            action: ()=>{actionActiv.add(recette.id)},
            html: 
            <div> 
                <span className='align--centre'> Ajouter</span>
                <span className="material-symbols-outlined btn">add_circle</span>
            </div>
            }
            ,{
                action: ()=>{favToggle(recette.id)} ,
                html: 
                <div> 
                    <span className='align--centre'> Favorie</span>
                    <span className="material-icons btn">{fav.has(recette.id) ? 'favorite' : 'favorite_border'}</span>
                </div>
                }
        ]
    ),[fav,favToggle,recette.id,recette.name]) 

  return (
    <div className={extend ?'contenair contenair--column recette recette--menu recette--menu--extend' : 'contenair contenair--column recette recette--menu'} >
            {gerer?
            <div className='recette__ruban contenair contenair--space-between'>
                <img onClick={()=>{modify(-1,recette.id)}} src="remove.png" alt="remove" />
                <div>Nombre de personne: {numbersOfpersonne}</div>
                <img onClick={()=>{modify(+1,recette.id)}} src="add.png" alt="add" />
            </div>
            :null}
            {/* {rubant.active ?
            <div className='recette__ruban contenair contenair--space-between'>
                {rubant.add !== false ? <img onClick={()=>{rubant.add(recette.id)}} src="add.png" alt="add" />:null}
                {rubant.favorie !== false ? <button onClick={()=>{rubant.favorie(recette.id)}} className='btn material-icons'>{fav.has(recette.id) ? 'favorite' : 'favorite_border'}</button> :null}
            </div>
            :null} */}
            <div className='contenair contenair--column  ' onClick={(e)=>{setExtend(!extend)}}>
                {img === false ? <Loading/> :<img className='recette__img' src={URL.createObjectURL(img)} alt="" />}
                <div className='contenair contenair--column recette__info'>
                    <div className='contenair contenair--space-between'>
                        <div className='contenair recette__name'> {recette.name}</div>
                        <div className='conteanir recette__option'>
                                <Actions icon={'more_vert'} offset={{left:'-85px'}} options={options}/>
                        </div>
                    </div>
                    <div className='contenair contenair--scrollX contenair--no-warp recette__tags'>{recette.categories.map((categorie)=>(<Tag key={recette.id+categorie.id} tag={categorie}/>))}</div>
                    <div className='contenair contenair--scrollY recette__description'>{recette.description}</div>
                    {extend ? 
                    <div className='contenair  contenair--scrollY menu__ingredients recette__ingredients'>
                        {recette.ingredients.map((ingredient)=>(<IngredientRond key={ingredient.idIngredient+'/'+recette.id} ingredient={ingredient}/>))}
                    </div>:null}
                    <div className='contenair contenair--centre btn btn--green btn--big' onClick={()=>{nav(numbersOfpersonne ? '/cuisiner/' + recette.id+`?nbPersonne=${numbersOfpersonne}`:'/cuisiner/' + recette.id)}}>Cuisiner</div>
                </div>
            </div>
        </div>
  )
}
