import React, {  useRef } from 'react'

export default function Carousel({children,className=''}) {
    const carousel = useRef()
    const carouselContent = useRef()

    return(
    <div ref={carousel} className= {`carousel ${className !== ''?`carousel--${className}`:''}  overflow--hidden`}>
        <div ref={carouselContent} className={`carousel__content ${className !== ''?`carousel__content--${className}`:''}  contenair--scrollX contenair--no-warp contenair`}>
            {children}
        </div>
    </div>
  )
}
