import React, { useContext, useEffect, useState } from 'react'
import RecetteMenu from './recettes/RecetteMenu'
import { authcontext } from '../contexte/auth/Authcontext'
import Loading from './Loading'
import RecetteMenuAdd from './recettes/RecetteMenuAdd'
import Repas from '../class/Repas'
import useFetchAuth from '../hook/useFetchAuth'

export default function Menu({semaine}) {
    const [gerer,setGerer] = useState(false)
    const [menu,setMenu] = useState(false)
    const [recettesRandom,setRecettesRandom] = useState(false)
    const {api,user,authFetch}= useContext(authcontext)

    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        setMenu(false)
        authFetch.get(`/menu/${semaine.week}-${semaine.year}`)
        .then((menu)=>{
            setMenu(menu)
            console.log(menu)
        }).then(async ()=>{
            console.log('la')
            const recettes =    await authFetch.get(`/recette/random/limit/${user.preference.nbRepas*5}?getIngredient='true`)
            setRecettesRandom(recettes)
        }).catch((err)=>{
            console.error(err)
        })
    },[setMenu,semaine,api,setRecettesRandom])

    /* useEffect(()=>{
        fetchAuth(api+`/recette/random/limit/${user.preference.nbRepas*5}?getIngredient='true`,'get')
        .then((recette)=>{
            setRecettesRandom(recette)
            console.log(recette)
        }).catch((err)=>{
            console.error(err)
        })
    },[setRecettesRandom,api,user]) */

    const modif = (n,id)=>{
        let cloneMenu = {...menu}
        
        const index = cloneMenu.repas.findIndex((repas)=>(repas.recette.id === id))
        cloneMenu.repas[index].numberOfPresonne += n
        if(cloneMenu.repas[index].numberOfPresonne < 1){
            let [{recette}] = cloneMenu.repas.splice(index,1)
            let cloneRecetteRandom = [...recettesRandom]
            cloneRecetteRandom.push(recette)
            console.log(cloneRecetteRandom)
            setRecettesRandom(cloneRecetteRandom)
            
            cloneMenu.numberOfRepas--
        }
        setMenu(cloneMenu)
    }

    const add = (recette)=>{
        const repas = new Repas({recette:recette,numberOfPresonne:user.preference.nbPers,idMenu:menu.id})
        let cloneMenu = {...menu}
        let cloneRecetteRandom = [...recettesRandom]

        cloneMenu.repas.push(repas)
        cloneMenu.numberOfRepas++
        console.log(recette)
        const index = cloneRecetteRandom.findIndex((recetteR)=>(recetteR.id === recette.id))
        console.log(cloneRecetteRandom[index])
        cloneRecetteRandom.splice(index,1)
        setRecettesRandom(cloneRecetteRandom)
        setMenu(cloneMenu)
    }

    const save = (menu)=>{
        let data = new FormData()
        data.append('week',menu.week)
        data.append('year',menu.year)
        data.append('numberOfRepas',menu.numberOfRepas)
        data.append('repas',JSON.stringify(menu.repas))
        authFetch.put(`/menu`,data)
        .catch((err)=>{
            console.error(err)
        })
    }

if(menu === false){
    return <Loading/>
}
  return (
    <div className='contenair contenair--centre'>
        <div className='menu__ruban contenair contenair--space-between'>
            <div className='contenair menu__ruban__info'>
                <span>Nombre de repas: {menu.numberOfRepas}</span>
                <span>Menus de la semaine {menu.week +'/'+menu.year}</span>
            </div>
            <div className='menu__ruban__action contenair' >
                {gerer? <img src="shuffle.png" alt="shuffle img" />  : null} 
                {gerer? <img src="save.png" alt="save img" onClick={()=>{save(menu)}}/>  : null} 
                <img src="settings.png" alt="settings img" onClick={(()=>{setGerer(!gerer)})}/>
            </div>
        </div>
        {menu.repas.map((repas)=><RecetteMenu key={`${repas.idMenu}/${repas.recette.id}`} modify={modif} gerer={gerer} numbersOfpersonne={repas.numberOfPresonne} recette={repas.recette}/>)}
        {recettesRandom === false || !gerer ? null:recettesRandom.map((recette)=>(<RecetteMenuAdd key={recette.id} add={add} recette={recette}/>)) }
    </div>
  )
}
