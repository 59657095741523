import React from 'react'

export default function Popup({titel,children,yes=(e)=>{e.stopPropagation()},no=(e)=>{e.stopPropagation()}}) {
  return (
    <div className='popup contenair contenair--column'>
                <div className='popup__header contenair contenair--centre'>{titel}</div>
                <div className='popup__body contenair contenair--centre contenair--scroll'>
                    {children}
                </div>
                <div className='popup__footer contenair contenair--space-evently'>
                  <button onClick={(e)=>{yes(e)}} className='btn btn--small'>{no === false ? 'ok' : 'oui'}</button>
                  {no !== false ? <button onClick={(e)=>{no(e)}} className='btn btn--small'>non</button>:
                      null
                    } 

                  
                    
                </div>
    </div>
  )
}
