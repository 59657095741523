import { useContext, useEffect, useState } from "react"
import { authcontext } from "../contexte/auth/Authcontext"
import useFetchAuth from "./useFetchAuth"

 const  useFetchRecettte = (id)=>{
    const {api,fav,authFetch} = useContext(authcontext)
    const [recette,setRecette] = useState(false)

    const fetchAuth = useFetchAuth()

    useEffect(()=>{
        console.log(fav.get(parseInt(id)))
        if (fav.has(parseInt(id))){
            setRecette(fav.get(parseInt(id)))    
        }else{
            authFetch.get(`/recette/${id}?getIngredient=true&getEtape=true`)
            .then((recette)=>{
                setRecette(recette)
            }).catch((err)=>{
                console.error(err)
            })
        } 
    },[api,id,setRecette])

    return recette
 }

 export default useFetchRecettte