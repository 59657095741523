import { useEffect, useState } from "react";

export default function useDebounce(value,delay){
    const [debounceValue,setDebounceValue] = useState("")
    useEffect(()=>{
        const timer = setTimeout(()=>{setDebounceValue(value)},delay || 500)
        
        return ()=>{
            clearTimeout(timer)
        }
    },[value,delay])

    return debounceValue
}