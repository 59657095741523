

class FetchAuthObject{
    constructor(token,host,urlRefeshToken){
        this.token = token
        this.refresh = null
        this.host = host
        this.try = 0
        this.urlRefeshToken = urlRefeshToken
    }

    async refreshToken(){

        const tryRefresh =new Promise(async (resolve,reject)=>{
          const reponce = await fetch(this.urlRefeshToken,{credentials: 'include'})
           if(reponce.ok){
            const newToken = await reponce.json()
            this.token = newToken.accessToken
            resolve(newToken)
            this.refresh = null
           }else{
            reject(reponce.status)
           }   
        })

        this.refresh = tryRefresh
        return tryRefresh
    }

    async resolveFetch(result){
        if(result.headers.get("Content-Type")?.includes('image')){
            const data = await result.blob()
            return data
        }
        if(result.headers.get("Content-Type")?.includes('application/json')){
            const data = await result.json()
            return data
        }
        if(result.headers.get("Content-Type")?.includes('text/plain')){
            const data = await result.text()
            return data
        }
        return null
    }

    get(endpoint,abort){
        const url = this.host + endpoint
        const header = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': `Bearer ${this.token}`
            },
            method: 'get',
            credentials: 'include',
            signal:abort
        }
        return new Promise(async (resolve,reject)=>{
            try {
                const reponce = await fetch(url,header)
                if(reponce.ok){
                    const data = await this.resolveFetch(reponce)
                    resolve(data)
                }
                if(reponce.status === 401 || reponce.status === 403){
                    
                    if(this.refresh === null){
                        console.log('first old',this.token)
                        await this.refreshToken()
                        console.log('first new',this.token)
                        if(this.try < 2){
                            const data = await this.get(endpoint)
                            resolve(data)
                        }
                    }else{
                        console.log('second old',this.token)
                        await this.refresh
                        console.log('second new',this.token)
                        if(this.try < 2){
                            const data = await this.get(endpoint)
                            resolve(data)
                        }
                    }
                }
                reject(reponce.status) 

            } catch (error) {
                reject(error)
            }
        })
    }

    postJson(endpoint,data){
        const url = this.host + endpoint
        const header = {
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
                'authorization': `Bearer ${this.token}`
            },
            method: 'post',
            body: JSON.stringify(data),
            credentials: 'include'
        }

        return new Promise(async (resolve,reject)=>{
            try {
                const reponce = await fetch(url,header)
                if(reponce.ok){
                    const data = await this.resolveFetch(reponce)
                    resolve(data)
                }
                if(reponce.status === 401 || reponce.status === 403){
                    this.try++
                    if(this.refresh === null){
                        await this.refreshToken()
                        if(this.try < 2){
                            const reponce2 = await this.postJson(endpoint,data)
                            resolve(reponce2)
                        }
                    }else{
                        await this.refresh
                        if(this.try < 2){
                            const reponce2 = await this.postJson(endpoint,data)
                            resolve(reponce2)
                        }
                    }
                }
                reject(reponce.status) 

            } catch (error) {
                reject(error)
            }
        })
    }

    post(endpoint,data){
        const url = this.host + endpoint
        const header = {
            headers: {
                'Accept': 'application/json',
                'authorization': `Bearer ${this.token}`
            },
            method: 'post',
            body: data,
            credentials: 'include'
        }

        return new Promise(async (resolve,reject)=>{
            try {
                const reponce = await fetch(url,header)
                if(reponce.ok){
                    const data = await this.resolveFetch(reponce)
                    resolve(data)
                }
                if(reponce.status === 401 || reponce.status === 403){
                    //this.try++
                    if(this.refresh === null){
                        await this.refreshToken()
                        if(this.try < 2){
                            const reponce2 = await this.post(endpoint,data)
                            console.log('la1')
                            resolve(reponce2)
                        }
                    }else{
                        await this.refresh
                        if(this.try < 2){
                            console.log('la2')
                            const reponce2 = await this.post(endpoint,data)
                            resolve(reponce2)
                        }
                    }
                }
                reject(reponce.status) 

            } catch (error) {
                reject(error)
            }
        })

    }
    
    put(endpoint,data){
        const url = this.host + endpoint
        const header = {
            headers: {
                'Accept': 'application/json',
                'authorization': `Bearer ${this.token}`
            },
            method: 'put',
            body: data,
            credentials: 'include'
        }
        return new Promise(async (resolve,reject)=>{
            try {
                const reponce = await fetch(url,header)
                if(reponce.ok){
                    const data = await this.resolveFetch(reponce)
                    resolve(data)
                }
                if(reponce.status === 401 || reponce.status === 403){
                    this.try++
                    if(this.refresh === null){
                        await this.refreshToken()
                        if(this.try < 2){
                            const reponce2 = await this.put(endpoint,data)
                            resolve(reponce2)
                        }
                    }else{
                        await this.refresh
                        if(this.try < 2){
                            const reponce2 = await this.put(endpoint,data)
                            resolve(reponce2)
                        }
                    }
                }
                reject(reponce.status) 

            } catch (error) {
                reject(error)
            }
        })
    }

    putJson(endpoint,data){
        const url = this.host + endpoint
        const header = {
            headers: {
                'Accept': 'application/json',
                'authorization': `Bearer ${this.token}`
            },
            method: 'put',
            body: data,
            credentials: 'include'
        }
        return new Promise(async (resolve,reject)=>{
            try {
                const reponce = await fetch(url,header)
                if(reponce.ok){
                    const data = await this.resolveFetch(reponce)
                    resolve(data)
                }
                if(reponce.status === 401 || reponce.status === 403){
                    this.try++
                    if(this.refresh === null){
                        await this.refreshToken()
                        if(this.try < 2){
                            const reponce2 = await this.putJson(endpoint,data)
                            resolve(reponce2)
                        }
                    }else{
                        await this.refresh
                        if(this.try < 2){
                            const reponce2 = await this.putJson(endpoint,data)
                            resolve(reponce2)
                        }
                    }
                }
                reject(reponce.status) 

            } catch (error) {
                reject(error)
            }
        })
    }

    patchJson(endpoint,data){
        const url = this.host + endpoint
        const header = {
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
                'authorization': `Bearer ${this.token}`
            },
            method: 'PATCH',
            body: JSON.stringify(data),
            credentials: 'include'
        }

        return new Promise(async (resolve,reject)=>{
            try {
                const reponce = await fetch(url,header)
                if(reponce.ok){
                    const data = await this.resolveFetch(reponce)
                    resolve(data)
                }
                if(reponce.status === 401 || reponce.status === 403){
                    this.try++
                    if(this.refresh === null){
                        console.log('first old',this.token)
                        await this.refreshToken()
                        console.log('first new',this.token)
                        if(this.try < 2){
                            const reponce2 = await this.patchJson(endpoint,data)
                            resolve(reponce2)
                        }
                    }else{
                        console.log('secon old',this.token)
                        await this.refresh
                        console.log('secon new',this.token)
                        if(this.try < 2){
                            const reponce2 = await this.patchJson(endpoint,data)
                            resolve(reponce2)
                        }
                    }
                }
                reject(reponce.status) 

            } catch (error) {
                reject(error)
            }
        })
    }

}

module.exports = FetchAuthObject