import React from 'react'
import Carousel from './Carousel'
import CardRecetteRepas from './recettes/CardRecetteRepas'
import { useNavigate } from 'react-router-dom'
import SemaineNumero from './SemaineNumero'

export default function MenuSemaine({menu}) {
    const {numberOfRepas,repas,week,year} = menu
    const nav = useNavigate()
    const parm = new URLSearchParams({week,year})
  return (
    <div className='contenair menu__card'>
        <div className='contenair contenair--space-between'>
            <SemaineNumero semaine={{week,year}} className={"semaine__numero--home"} />
            <button onClick={()=>{nav(`/liste-de-course?`+parm.toString(),{replace:true})}} className='btn'><span className="material-symbols-outlined">receipt_long</span></button>
        </div>
        <Carousel className='wide'>{repas.map((repa,index)=>(<CardRecetteRepas key={'repas'+ repa.idMenu+'/'+ index} repas={{...repa.recette,numberOfPresonne:repa.numberOfPresonne}}/>))}</Carousel>
        <div className='contenair'><button onClick={()=>{nav(`/MesMenus?`+parm.toString(),{replace:true})}} className='btn btn--big btn--green'>Gerée le menu</button></div>
    </div>
  )
}
