import React from 'react'

export default function SemaineNumero({set= ()=>{},semaine,select={week:53},className}) {
  console.log(semaine)
if(select.week === semaine.week && select.year === semaine.year){
    return (
      <div className={'contenair contenair--centre semaine__numero semaine__numero--active ' + className} onClick={()=>{set(semaine)}}>
        <samp className='semaine__numero--abr'>Sem.</samp>
        <span className='semaine__numero__num'>N°{semaine.week}</span>
      </div>
    )
}
return(
    <div className={'contenair contenair--centre semaine__numero ' + className} onClick={()=>{set(semaine)}}>
        <samp className='semaine__numero--abr'>Sem.</samp>
        <span className='semaine__numero__num'>N°{semaine.week}</span>
    </div>
)
}
