import React, { useEffect, useState } from 'react'

export default function CustumSelect({className='',options=[],select,style={},close,position,offset = {left:'0px'}}){
  const [loading,setLoading] = useState(false)
  
  

  useEffect(()=>{
    if(loading){
      console.log(options)
      document.addEventListener('click',close)
      return ()=>{
        document.removeEventListener('click',close)
      }
    }else{
      setLoading(true)
    }
  },[loading])

  return (
    <div style={offset} className={`contenair contenair--centre custumSelect__warper overflow--hiden ${className !=='' ? 'custumSelect__warper ' +className :''} ${position !== '' ?'custumSelect__warper ' +className+position:null }` } onClick={(e)=>{e.stopPropagation();close(e)}}>
        <div className={`custumSelect contenair--scroll ${className !=='' ? 'custumSelect' +className :''}` } style={style}>
            {options.map((option,index)=>(<div key={`select/${index}`} onClick={(e)=>{option.action === false || option.action === undefined ? select(option.data) : option.action(option.data);close(e)}} className= {`custumSelect__option ${className !=='' ? 'custumSelect__option ' +className :''}` }>{option.html}</div>))}
        </div>
    </div>
  )
}
