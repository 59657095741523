import React, { useContext, useState } from 'react';
import { authcontext } from '../contexte/auth/Authcontext';
import useFetchImgingredient from '../hook/useFetchImgIngredient';
import Loading from './Loading';
import useFetchAuth from '../hook/useFetchAuth';

const Ingredient = ({path,name,quantity,unit,etat,id,rond=false,update}) => {
    const {api,authFetch} = useContext(authcontext)
    const img = useFetchImgingredient(path)
    const [state,setState] = useState(etat === 0 || etat === false? false : true)

    const fetchAuth = useFetchAuth()
    
    const toogle =()=>{
        if(state !== 3){
            const prevState = state
            setState(3)
            authFetch.postJson(`/listedecourse/toggleItems`,{ids:id})
           .then((items)=>{
                update(items)
            }).catch((err)=>{
                console.log(err)
                setState(prevState)
            })
        }
    }


    return (
        <div onClick={()=>{toogle()}} className={ rond ?'ingredient ingredient--rond contenair contenair--centre contenair--space-evently':'ingredient contenair contenair--centre contenair--space-evently' } >
            {img === false ? <Loading/> :<img src= {URL.createObjectURL(img)} alt="" />}
            <div className='ingredient__nom' >{name}</div>
            {quantity === null ? null : <div className='ingredient__quantity'>{quantity}</div>}
            <div className='ingredient__unit'>{unit}</div>
            <div className='ingredient__state' data-state={`${state}`}></div> 
        </div>
    );
};

export default Ingredient;