import React, { useState } from 'react'
import CustumSelect from './CustumSelect'

export default function Actions({options,icon,className,offset}) {
    const [toggle,setToggle] = useState(false)
    function close(e){
        e.stopPropagation()
        setToggle(false)
    }

  return (
    <div className='relative'>
        <i onClick={(e)=>{e.stopPropagation(); setToggle((prev)=>(!prev))}} className='material-symbols-outlined btn'> {icon === undefined ? 'more_horiz' : icon}</i>
        {toggle?<CustumSelect close={close} className={'--relative ' + className} position={'--bottom'} offset={offset} options={options} />:null}
    </div>

  )

}
