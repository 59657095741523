import React from 'react'
import useFetchImgEtape from '../hook/useFetchImgEtape'
import Loading from './Loading'

export default function Etape({etape,idRecette,classWarper,classInstruction}) {
    const img = useFetchImgEtape(idRecette,etape.ordre)
  return (
    <div className={'contenair '+classWarper}>
        {img === false? <Loading/> : <img src={URL.createObjectURL(img)} alt='img ulistration etape'/>}
        {/* <div className='contenair etape__info'>
            <span className='etape__info__name'>{etape.name}</span>
        </div> */}
        <div className={'etape__instruction '+classInstruction}>
            <ol>
                {etape.description.split(/[.!?_]+/).filter((string)=>(string.trim() !== '')).map((instruction,index)=>(<li key={etape.id+'/'+index}>{instruction}</li>))}
            </ol>
        </div>
    </div>
  )
}
